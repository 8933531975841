import * as React from "react";
import { MDXProvider } from "@mdx-js/react";
import styled from "@emotion/styled";
import {
  H2,
  Link,
  Paragraph,
  SerifFont,
  FontWeight600,
} from "../design-system";

const Main = styled.main`
  margin: 2rem auto;
  /* this is 44rem * 16px  but 1rem != 16px here*/
  max-width: 704px;
  ${SerifFont}
`;

export default function Layout({ children }) {
  return (
    <MDXProvider
      components={{
        // Map HTML element tag to React component
        a: Link,
        h2: H2,
        p: Paragraph,
        strong: FontWeight600,
      }}
    >
      <Main>{children}</Main>
    </MDXProvider>
  );
}
