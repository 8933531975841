import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Bready = <StaticImage alt="Bready" src="./bready.png" />;
export const Gitphy = <StaticImage alt="Gitphy" src="./gitphy.png" />;
export const GraphTv = <StaticImage alt="GraphTV" src="./graphtv.png" />;
export const Plasmyd = <StaticImage alt="Plasmyd" src="./plasmyd.png" />;
export const RandomcolorPy = (
  <StaticImage alt="randomcolor-py" src="./randomcolor-py.png" />
);
export const Releaselog = (
  <StaticImage alt="Releaselog" src="./releaselog.png" />
);
export const TheDaysSidebar = (
  <StaticImage alt="The Days Sidebar" src="./the-days-sidebar.png" />
);
export const VectorLogos = (
  <StaticImage alt="Vector Logos" src="./vector-logos.png" />
);
export const Wwpgd = (
  <StaticImage alt="What Would Paul Graham Do?" src="./wwpgd.png" />
);
