import * as React from "react";
import styled from "@emotion/styled";
import { default as LogoImage } from "../images/Logo";
import { Link } from "../design-system";

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-family: "Source Sans Pro";
`;

const Logo = styled(LogoImage)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const Name = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.01rem;
`;

const Nav = styled.nav`
  font-size: 0.75rem;
`;

const Dot = styled.span`
  margin: 0 0.5rem;
`;

const links = [
  { name: "Projects", href: "#projects" },
  { name: "Github", href: "https://github.com/kevinwuhoo" },
  { name: "Twitter", href: "https://twitter.com/kevinwuhoo" },
];

const navLinks = links.map(({ name, href }, index) => (
  <React.Fragment key={name}>
    <Link to={href}>{name}</Link>
    {index < links.length - 1 && <Dot>&bull;</Dot>}
  </React.Fragment>
));

export default () => (
  <Header>
    <Logo />
    <Nav>
      <Name>Kevin Wu</Name>
      <div>{navLinks}</div>
    </Nav>
  </Header>
);
