/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Project from "./Project";
import * as ProjectImages from "../../images/projects/index";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "projects",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#projects",
    "aria-label": "projects permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Projects"), "\n", React.createElement(Project, {
    image: ProjectImages.VectorLogos
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Vector Logos"), " —\n", React.createElement(_components.a, {
    href: "https://www.figma.com/community/plugin/854152902511629627/Vector-Logos"
  }, "Figma"), ",\n", React.createElement(_components.a, {
    href: "https://github.com/kevinwuhoo/vector-logos-figma-plugin"
  }, "Github")), React.createElement(_components.p, null, "A Figma plugin that enables searching for and inserting vector versions of\nlogos. 147th most popular plugin with 31k installs (as of Aug 2022).")), "\n", React.createElement(Project, {
    image: ProjectImages.GraphTv
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "GraphTV"), " (Complete)"), React.createElement(_components.p, null, "Visualize per-episode ratings of any TV series.\n", React.createElement(_components.a, {
    href: "https://www.avclub.com/website-turns-any-tv-show-into-a-chart-revolutionizes-1798267065"
  }, "AV Club"), "\ntouted that it “revolutionizes arguing about TV”. Also covered in\n", React.createElement(_components.a, {
    href: "http://www.wired.com/2014/03/graph-quality-every-tv-show-ever-amazing-site/"
  }, "Wired"), ",\n", React.createElement(_components.a, {
    href: "https://gizmodo.com/check-the-tv-ratings-of-any-show-on-imdb-using-this-coo-1550258159"
  }, "Gizmodo"), ",\n", React.createElement(_components.a, {
    href: "https://flowingdata.com/2014/03/24/graph-tv-shows-ratings-by-episode/"
  }, "Flowing Data"), ",\n", React.createElement(_components.a, {
    href: "https://www.fastcompany.com/3028097/test-it-did-your-favorite-tv-show-get-better-or-worse"
  }, "Fast Company"), ",\n", React.createElement(_components.a, {
    href: "https://time.com/28906/this-website-graphs-the-popularity-of-tv-shows/"
  }, "Time"), ",\nand others.")), "\n", React.createElement(Project, {
    image: ProjectImages.Gitphy
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Gitphy"), " —\n", React.createElement(_components.a, {
    href: "https://chrome.google.com/webstore/detail/gitphy/kbnbfpmphfpcndlokabhemafnjhkcffc"
  }, "Chrome"), ",\n", React.createElement(_components.a, {
    href: "https://addons.mozilla.org/en-US/firefox/addon/gitphy/"
  }, "Firefox"), ",\n", React.createElement(_components.a, {
    href: "https://github.com/kevinwuhoo/gitphy"
  }, "Github")), React.createElement(_components.p, null, "Search for and insert GIFs into Github without leaving the textarea. As\ncolleagues have testified, useful for inserting a bit of fun into pull request\nreviews and issues.")), "\n", React.createElement(Project, {
    image: ProjectImages.Bready
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Bready"), " — ", React.createElement(_components.a, {
    href: "https://bready.io/"
  }, "Site")), React.createElement(_components.p, null, "Convert volumetric recipes into mass for maximal baking reproducibility.")), "\n", React.createElement(Project, {
    image: ProjectImages.RandomcolorPy
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "randomcolor-py"), " — ", React.createElement(_components.a, {
    href: "https://pypi.org/project/randomcolor/"
  }, "PyPI"), ",\n", React.createElement(_components.a, {
    href: "https://github.com/kevinwuhoo/randomcolor-py"
  }, "Github")), React.createElement(_components.p, null, "A python package that generates attractive random colors. It sees about ~5.9k\nmonthly downloads and has 256k total downloads (as of Aug 2022).")), "\n", React.createElement(Project, {
    image: ProjectImages.TheDaysSidebar
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "The Days Sidebar"), " —\n", React.createElement(_components.a, {
    href: "https://chrome.google.com/webstore/detail/the-days-sidebar/njkkgfngelkphhfadnpealmkjmdjcioc?hl=en"
  }, "Chrome"), ",\n", React.createElement(_components.a, {
    href: "https://github.com/kevinwuhoo/thedayssidebar"
  }, "Github")), React.createElement(_components.p, null, "A minimal browser new tab page that embeds the day’s\n", React.createElement(_components.a, {
    href: "https://sidebar.io/"
  }, "Sidebar.io"), " links into color inspiration from\n", React.createElement(_components.a, {
    href: "http://www.thedayscolor.com/"
  }, "The Day’s Color"), ". Helps me keep up with designs\ntrends and news.")), "\n", React.createElement(Project, {
    image: ProjectImages.Wwpgd
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "What Would PG Do?"), " (Complete)"), React.createElement(_components.p, null, "A site where you could search Paul Graham’s essays.\n", React.createElement(_components.a, {
    href: "https://news.ycombinator.com/item?id=4656378"
  }, "Got made fun of by the internet"), ",\nbut what’s life without a bit of levity? The first product I built that had\ntraction. Covered by\n", React.createElement(_components.a, {
    href: "https://techcrunch.com/2012/10/15/what-would-paul-graham-do/"
  }, "TechCrunch"), ",\n", React.createElement(_components.a, {
    href: "https://www.inc.com/kathleen-kim/got-questions-about-start-ups-just-ask-paul-graham.html"
  }, "Inc"), ",\nand others.")), "\n", React.createElement(Project, {
    image: ProjectImages.Releaselog
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Releaselog"), " — ", React.createElement(_components.a, {
    href: "https://www.releaselog.xyz/facebook/react/releases"
  }, "Site")), React.createElement(_components.p, null, "Releaselog pulls in all of a project’s releases onto a single page so you can\nuse ctrl+f to your heart’s content without dealing with pagnation.")), "\n", React.createElement(Project, {
    image: ProjectImages.Plasmyd
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Plasmyd"), " (Acquired)"), React.createElement(_components.p, null, "Collaborate over scientific papers. Think RapGenius for published papers. Read\nabout our\n", React.createElement(_components.a, {
    href: "https://techcrunch.com/2012/09/27/meet-plasmyd-a-search-enginediscussion-platform-just-for-scientists/"
  }, "our launch on Techcrunch"), ".\nWe were\n", React.createElement(_components.a, {
    href: "https://techcrunch.com/2013/10/23/academia-plasmyd/"
  }, "acquired by Academia.edu"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
