import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Link from "./Link";

export const SansSerifFont = css`
  font-family: "Source Sans Pro";
`;

export const SerifFont = css`
  font-family: "Source Serif Pro";
`;

const header = css`
  ${SansSerifFont}
`;

export const H2 = styled.h2`
  ${header}
  font-size: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
`;

export const Paragraph = styled.p`
  margin: 0;

  & + & {
    margin-top: 0.5rem;
  }
`;

export const FontWeight600 = styled.strong`
  font-weight: 600;
`;

export { Link };
