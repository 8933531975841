import * as React from "react";
import type { HeadFC } from "gatsby";

import Intro from "../components/index/Intro";
import Contact from "../components/index/Contact";
// import Extracurriculars from "../components/index/Extracurriculars";
import Projects from "../components/index/Projects";
import Layout from "../components/Layout";
import Header from "../components/Header";

const IndexPage = () => (
  <Layout>
    <Header />
    <Intro />
    <Projects />
    {/* <Extracurriculars /> */}
    <Contact />
  </Layout>
);

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;
