import * as React from "react";
import styled from "@emotion/styled";

interface Props {
  children: React.ReactNode;
  image: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;

  @media (max-width: 641px) {
    flex-direction: column;
    margin-top: 2rem;
  }
`;

const Right = styled.div`
  @media (max-width: 641px) {
    margin-top: 1rem;
  }
`;

const Left = styled.div`
  width: 6rem;
  height: 4rem;
  margin-right: 1.5rem;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  flex: none;

  /* force border radius on child (image) */
  border-radius: 0.15rem;
  overflow: hidden;

  @media (max-width: 641px) {
    width: auto;
    height: auto;
    justify-self: center;
  }
`;

export default function ({ children, image }: Props) {
  return (
    <Wrapper>
      <Left>{image}</Left>
      <Right>{children}</Right>
    </Wrapper>
  );
}
